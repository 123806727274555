import { setGlobal } from 'reactn';
import setAuthToken from "./actions/setAuthToken";
import checkUserToken from "./actions/checkUserToken";
import authenticationClient from "./actions/authenticationClient";
import authenLivechat from "./actions/authenLivechat";
import initIO from "./actions/initIO";
import store from "./store";
import jwtDecode from 'jwt-decode';
import Config from "./config";
import { v4 as uuidv4 } from 'uuid';
import { Cookies } from "react-cookie";

const init = async () => {
    // try {
        document.addEventListener('gesturestart', e => {
            e.preventDefault();
        });
    
        if (localStorage.getItem('app') !== 'Clover 2.x.x') {
            localStorage.clear();
            localStorage.setItem('app', 'Clover 2.x.x');
        }

        const cookies = new Cookies();

        //Lấy token từ query params (trường hợp những boxchat client)
        let chatMode = 'server';
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token'); 
        const channelParam = urlParams.get('channel');
        let tokenChathub = localStorage.getItem('token');
        let brand = cookies.get('BRAND');
        let userString = localStorage.getItem('user');
        let user = userString ? JSON.parse(userString) : null;

        let eToken = '';
        if(tokenParam){ //Chat client có token (Mobile App)
            eToken = tokenParam;
            chatMode = 'client';
            localStorage.removeItem("token");
            localStorage.setItem("channel_client", channelParam);
        }
        else if(channelParam){ //Chat client không có token (webiste)
            chatMode = 'client';
            localStorage.removeItem("token");
            localStorage.setItem("channel_client", channelParam);
        }
        else{ // ChatHub Admin
            eToken = cookies.get('EPOINTS_SSO');
            // eToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3N0YWZmLmVwb2ludHMudm4vdXNlci9yZWZyZXNoLXRva2VuIiwiaWF0IjoxNzI3MDY3MTI4LCJleHAiOjE3MjcwODg3NjcsIm5iZiI6MTcyNzA2NzE2NywianRpIjoiUTM0a0ZweDgxOWdEZjhUSiIsInN1YiI6MjA3LCJwcnYiOiJhMGYzZTc0YmVkZjUxMmM0Nzc4Mjk3ZGU1ZjkyMDg2ZGFkMzljYTlmIiwic2lkIjoiYWRtaW5AbWF0dGhld3NsaXF1b3IuY29tIiwicGhvbmUiOiJhZG1pbkBtYXR0aGV3c2xpcXVvci5jb20iLCJicmFuZF9jb2RlIjoibWF0dGhld3NsaXF1b3IiLCJpbWVpIjoicG9ydGFsMTIzIn0.k0Dfl7rrK5BEhka7naD-T5dHYZnwNP3BnQmY5ddmPwI";
            Config.epointsToken = eToken;
            localStorage.removeItem("channel_client");
        }

        localStorage.setItem('chat_mode', chatMode);
        localStorage.removeItem("uid");
    
        if(!eToken){ //Live chat
            const branchId = urlParams.get('branch_id');
            const customerId = urlParams.get('customer_id');
            const fullName = urlParams.get('full_name');
            const phone = urlParams.get('phone');
            brand = urlParams.get('brand');

            cookies.set('BRAND', brand);
            let params = {
                channel: channelParam,
                branch_id: branchId,
            };

            if(phone){ //Người dùng livechat đã đăng nhập
                params.customer_id = customerId;
                params.phone = phone;
                params.full_name = fullName;
            }
            else{ //Người dùng livechat chưa đăng nhập
                let livechatUid = localStorage.getItem('livechat-uid');
                if(!livechatUid){
                    livechatUid = uuidv4();
                    localStorage.setItem('livechat-uid', livechatUid);
                }

                params.uuid = livechatUid;
            }

            const response = await authenLivechat(brand, params);

            const resultLivechat = response.data;

            // Lấy token chathub sau khi Authen
            if (!resultLivechat || resultLivechat.error) {
                console.log({error: resultLivechat});
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                tokenChathub = localStorage.getItem('token');
            } else {
                tokenChathub = resultLivechat.token;
                userString = JSON.stringify(jwtDecode(resultLivechat.token));
                user = userString ? JSON.parse(userString) : null;

                localStorage.setItem('token', tokenChathub);
                localStorage.setItem('user', JSON.stringify(jwtDecode(resultLivechat.token)));
            }
        
            if (tokenChathub) {
                setAuthToken(tokenChathub);
                store.dispatch(initIO(tokenChathub));
            }
        }
        else{ // Chathub Admin/Client
            const decoded = jwtDecode(eToken, {complete: true});

            brand = decoded?.brand_code ?? decoded?.brand;
            cookies.set('BRAND', brand);

            let usernamePortal = decoded?.sub;
            if(usernamePortal){
                localStorage.setItem('uid', usernamePortal);
            }
        
            if(tokenChathub && eToken){
                const eDecoded = jwtDecode(eToken, {complete: true});
                const decoded = jwtDecode(tokenChathub, {complete: true});
        
                if(eDecoded.sid !== decoded.sid){
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    tokenChathub = null;
                }
            }
        
            if(!tokenChathub){
                tokenChathub = eToken;
            }

            const dateNow = new Date();
            let isExpired = (decoded.exp * 1000 < dateNow.getTime());
            let result;

            if (!isExpired) { //Authen
                try {
                    let res = null;
                    if(tokenParam){
                        res = await authenticationClient({token: eToken, channelParam}, brand);
                    }
                    else{
                        res = await checkUserToken(eToken, brand);
                    }

                    result = res.data;

                } catch (e) {
                    console.log(e)
                    result = null;
                }
            }

            // Lấy token chathub sau khi Authen
            if (!result || result.error) {
                console.log({error: result});
                localStorage.removeItem("token");
                localStorage.removeItem("user");
                tokenChathub = localStorage.getItem('token');
            } else {
                tokenChathub = result.token;
                userString = JSON.stringify(jwtDecode(result.token));
                user = userString ? JSON.parse(userString) : null;

                localStorage.setItem('token', tokenChathub);
                localStorage.setItem('user', JSON.stringify(jwtDecode(result.token)));
            }
        
            if (tokenChathub) {
                setAuthToken(tokenChathub);
                store.dispatch(initIO(tokenChathub));
            }
        }
    
        const state = {
            version: '2.5.2',
            entryPath: window.location.pathname,
            token: tokenChathub,
            brand: brand ?? null,
            user: user || (tokenChathub ? jwtDecode(tokenChathub) : {}),
            channels: [],
            rooms: [],
            searchResults: [],
            searchResultsATG: [],
            favorites: [],
            meetings: [],
            notification: [],
            nav: 'rooms',
            search: '',
            over: null,
            isPicker: false,
            messages: [],
            streams: [],
            inCall: false,
            video: true,
            audio: true,
            audioStream: null,
            videoStream: null,
            screenStream: null,
            callStatus: null,
            counterpart: null,
            callDirection: null,
            meeting: null,
            showPanel: true,
            panel: 'standard',
            searchPage: '',
            newGroupUsers: [],
            newGroupUsersATG: [],
            openInfo: false,
            showReply: false,
            showModalUpdateTitle: false,
            showModalAddToGroup : false,
            showModalAddTag : false,
            showModalForward : false,
            roomActive : false,
            reply: false,
            messageReply: false,
            showConfirm: false,
            showAlert: false,
            currentMessage: '',
            messageGetData: [],
            messageModeAction: 'new',
            messageForward: [],
            userSelectedTag: [],
            userActiveTag: [],
            customerInfoGlobal: [],
            showPipeline: false,
            routeLists: [],
            parentDomain: '',
            keywordCustomer: '',
            chatMode: chatMode
        };
    
        setGlobal(state).then(() => console.log('Global state init complete!'));
    // } catch (error) {
    //     console.log('Token Missing: ', error.message);
    // }
}

export default init;
