import React, {useEffect, useState, useContext, useRef} from 'react';
import "./TopBar.sass";
import "./TopBar.css";
import {
    FiArrowLeft,
    FiMoreHorizontal,
    FiTrash,
    FiSlash,
    FiTablet, FiEdit
} from 'react-icons/fi';
import {useGlobal} from "reactn";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import clearMessageApi from "../../../actions/clearMessage";
import blockUserApi from "../../../actions/blockUser";
import moment from "moment";
import {AiOutlinePushpin, BiMessageRoundedDetail} from "react-icons/all";
import MasterConstant from "../../../constants/MasterConstant";
import updateGroup from "../../../actions/updateGroup";
import { AppContext } from '../../../context/AppContext';
import {Image, Tooltip, OverlayTrigger} from "react-bootstrap";
import icons from "../../../assets";
import Config from "../../../config";

const TopBar = ({back, loading, reloadRooms}) => {
    const onlineUsers = useSelector(state => state.io.onlineUsers);
    const room = useSelector(state => state.io.room) || {};
    const user = useGlobal('user')[0];
    const history = useHistory();

    const chatMode = useGlobal('chatMode')[0];
    const [isBlock, setIsBlock] = useState(false);
    const { showAlertAction, showConfirmAction, i18n } = useContext(AppContext);

    const [openInfo, setInfo] = useGlobal('openInfo');
    const setShowModalUpdateTitle = useGlobal('showModalUpdateTitle')[1];

    let userChat = room.owner;

    if (userChat && !userChat.firstName) {
        userChat = {...userChat, firstName: 'Deleted', lastName: 'User'};
    }

    useEffect(() => {
        if(userChat){
            setIsBlock(userChat.isBlocked);
        }
    }, [userChat]);

    const getTitleRoom = () => {
        let title = '';

        if(chatMode === 'client'){
            title = room.channel.nameApp || '';
        }

        else if(room && userChat){
            let userName = ((room.length > 0 && room.customerName !== '') ? room.customerName : `${userChat.firstName} ${userChat.lastName}`);
            title = userName ? userName.substr(0, 50) : '';
        }

        return `${title}${title.length > 55 ? '...' : ''}`;
    }

    const handleIconChannel = (source) => {
        if(source === 'facebook'){
            return icons.Facebook;
        }
        else if(source === 'zalo'){
            return icons.Zalo;
        }
        else{
            return icons.ClientChat;
        }
    }

    const IconCustomer = () => {
        let icon = '';
        let title = '';

        if(!userChat){
            return icon;
        }

        if(userChat.customerId){
            icon = icons.Customer;
            title = i18n.t('customer');
        }
        else if(userChat.cpoCustomerId){
            icon = icons.CpoCustomer;
            title = i18n.t('lead');
        }

        if(icon !== ''){
            return (<OverlayTrigger placement="bottom" overlay={generateTooltip(title)}>
                <span className="icon-user-room">
                    <Image width="16" src={icon}/>
                </span>               
            </OverlayTrigger>)
        }

        return icon;
    }

    const toggleInfoOn = () => {
        setInfo(true);
    };

    const toggleInfoOff = () => {
        setInfo(false);
    };

    const Online = ({userChat}) => {
        const onlineUsers = useSelector(state => state.io.onlineUsers);
        const prevStatusRef = useRef(false);
        const [lastOnline, setLastOnline] = useState(null);

        useEffect(() => {
            if(userChat){
                if (prevStatusRef.current && onlineUsers.filter(u => u.id === userChat._id).length === 0) setLastOnline(moment().valueOf());
                prevStatusRef.current = onlineUsers.filter(u => u.id === userChat._id).length > 0;
            }
        }, [onlineUsers, userChat]);
        
        if (lastOnline) return `${i18n.t('online')}: ${moment(lastOnline).fromNow()}`;
        return `${i18n.t('online')}: ${userChat && userChat.lastOnline ? MasterConstant.timeSince(room.lastUpdate, i18n) + ` ${i18n.t('ago')}` : 'Never'}`;
    };

    // const getStatus = () => {
    //     if (room.isGroup) return null;
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'busy').length > 0) return 'busy';
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'online').length > 0) return 'online';
    //     if (onlineUsers.filter(u => u.id === userChat._id && u.status === 'away').length > 0) return 'away';
    //     return null;
    // };

    // const ContentPin = () => {
    //     return MasterConstant.contentReply(room.pinMessage, 300);
    // };

    // const unPinMessage = async () => {
    //     const res = await updateGroup({
    //         type: 'single-data', roomId: room._id, data: null, field : 'pinMessage'
    //     });
    // }

    const handleActionClearMessage = () => {
        showConfirmAction(handleClearMessage, `${i18n.t('confirm_delete_chat_history')}?`);
    }

    const handleActionBlockRoom = () => {
        showConfirmAction(handleBlockChat, isBlock ? `${i18n.t('confirm_unblock_user')}?` : `${i18n.t('confirm_block_user')}?`);
    }

    const handleClearMessage = () => {
        clearMessageApi({roomId: room._id}).then(async (res) => {
            if(res.data){
                reloadRooms();
                showAlertAction('success', res.data.message);
                await history.replace('/');
            }
        }).catch(err => console.log('clearMessageApi', err));
    }

    const handleBlockChat = () => {
        blockUserApi({userId: userChat._id, isBlocked: !isBlock}).then(async (res) => {
            if(res.data.error === 0){
                let elmAction = document.getElementsByClassName('action-event');
                if(isBlock){
                    for await (let item of elmAction) {
                        item.classList.remove('prevent-events')
                    }
                }
                else{
                    for await (let item of elmAction) {
                        item.classList.add('prevent-events')
                    }
                }
                showAlertAction('success', res.data.message);
                setIsBlock(!isBlock);
                reloadRooms();
            }
        }).catch(err => console.log('blockUserApi', err));
    }

    const getColorBlockIcon = () => {
        return isBlock ? '#dd2c00' : '#666666';
    }

    const generateTooltip = (content) => {
        return <Tooltip>{content}</Tooltip>;
    }

    const Picture = ({user = {}, room}) => {

        let brand = useGlobal('brand')[0];
    
        let firstName = user.firstName || "User";
        let lastName = user.lastName || "Name";

        if(chatMode === 'client'){
            if(room?.channel?.avatar){
                return <img className="picture" src={room.channel.avatar} alt="Avatar Channel"/>;     
            }
            else{
                const channelName = room.channel.nameApp;
                return <div className="img">{channelName.substr(0,1).toUpperCase()}</div>
            }
        }
        
        if (room && room.shieldedID){
            return <img className="picture" src={`${Config.url || ''}/api/images/${room?.shieldedID}/256/${brand}`} alt="Avatar 1"/>;        
        }
        else
            return <div className="img">{firstName.substr(0,1).toUpperCase()}</div>;
    };

    return (
        <>
        <div className="top-bar uk-flex uk-flex-between uk-flex-middle">
            <div className="nav uk-flex uk-flex-middle">
                <div className="button mobile" onClick={() => back()}>
                    <FiArrowLeft/>
                </div>
                {!loading &&
                <div className="uk-flex uk-flex-middle">
                    <div className="profile conversation">
                        <Picture user={userChat} room={room}/>
                        <Image className="social-icon" width="20" src={handleIconChannel(room.source)}/>
                    </div>
                    {/* {getStatus() && <div className={`dot ${getStatus()}`}/>} */}
                </div>
                }
                {!loading &&
                <div className="text">
                    <div className="title">
                        {getTitleRoom()}
                        {user.id === room.owner && (
                            <span className="action-update-title" onClick={() => setShowModalUpdateTitle(true)}>
                                <FiEdit/>
                            </span>
                        )}
                        {<IconCustomer/>}
                    </div>
                    {chatMode === 'server' && <div className="message">{<Online userChat={userChat}/>}</div>}
                </div>
                }
            </div>
            <div className="nav list-action-conversation">
                <OverlayTrigger placement="bottom" overlay={generateTooltip(i18n.t('delete_chat_history'))}>
                    <span className="icon-remove-chat" onClick={() => handleActionClearMessage()}><FiTrash/></span>
                </OverlayTrigger>

                {room.source !== 'client' &&
                    <OverlayTrigger placement="bottom" overlay={generateTooltip(isBlock ? i18n.t('unblock_user') : i18n.t('block_user'))}>
                        <span className="icon-slash-chat" style={{ color: getColorBlockIcon()}}
                            onClick={() => handleActionBlockRoom()}>
                            {<FiSlash/>}
                        </span>
                </OverlayTrigger>}
                
                {openInfo && (<div className={`button active`} onClick={() => toggleInfoOff()}>
                    <FiTablet/>
                </div>)}
                {!openInfo && (<div className={`button`} onClick={() => toggleInfoOn()}>
                    <FiTablet/>
                </div>)}
            </div>
            {/* {room.pinMessage && (
                <div className="pin_message">
                    <span className="img_pin_message">
                        <BiMessageRoundedDetail />
                    </span>
                    <div className="block_pin_message">
                        <p><strong>Message from {room.pinMessage.author.firstName} {room.pinMessage.author.lastName} : </strong></p>
                        <span><ContentPin/></span>
                    </div>
                    <div className="uk-inline action_pin_message">
                        <div className="button button_hover cursor-pointer" type="button">
                            <FiMoreHorizontal/>
                        </div>
                        <div data-uk-dropdown="mode: click; offset: 5; boundary: .top-bar; pos: bottom-right"
                            className="uk-dropdown  action_member action_show">
                            <div className="link color-red" onClick={unPinMessage}><div className="icon"><AiOutlinePushpin/></div> Unpin </div>
                        </div>
                    </div>
                </div>
            )} */}
        </div>
        </>
    );
}

export default TopBar;
