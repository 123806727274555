import axios from "axios";
import Config from "../config";

const authenLivechat = (brand, params) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/livechat/config-livechat",
        data: {
            uuid: params.uuid, 
            social_channel_id: params.channel, 
            branch_id: params.branch_id,
            customer_id: params.customer_id,
            full_name: params.full_name,
            phone: params.phone
        },
        headers: {
            'brand-code': brand || 'matthewsliquor'
        }
    });
};

export default authenLivechat;
